import React, { useEffect, useState } from "react";
import "./Checkout.css";
import {
  Button,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Row,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useShopData } from "../../redux/context";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useAuth } from "../../service/AuthContext";

export const Checkout = () => {
  const [validated, setValidated] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showA, setShowA] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(0);
  const productsToCart = useShopData().data.cartItems;
  const { voucherValue } = useShopData().data.voucher;
  const history = useHistory();
  const { updateCart } = useShopData();

  const { currentUser } = useAuth();
  const addresses = useShopData().data.addresses;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();
      window.scrollTo({ top: 0, behavior: "smooth" });
      setShowA(true);
      setButtonDisabled(true);
      setTimeout(() => {
        localStorage.removeItem("items");
        updateCart([]);
        history.push("/");
      }, 5000);
    }
  };

  const calculateSum = (products) => {
    let sum = 0;
    products?.forEach(({ price, quantity }) => {
      sum += price * quantity;
    });
    return sum;
  };

  useEffect(() => {
    if (productsToCart.length > 0) {
      setTotalPrice(calculateSum(productsToCart));
    } else {
      history.push("/");
    }
  }, [productsToCart]);

  const SHIP_TAX = 20;

  return (
    <>
      <div
      // aria-live="polite"
      // aria-atomic="true"
      // className="bg-dark position-relative"
      // style={{ minHeight: "240px" }}
      >
        <ToastContainer
          position="top-end"
          className="p-3"
          style={{ zIndex: 1 }}
        >
          <Toast
            className="checkout-toast"
            // delay={3000}
            // autohide
            show={showA}
            onClose={() => {
              setShowA(false);
            }}
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Vinylize</strong>
              <small>1 mins ago</small>
            </Toast.Header>
            <Toast.Body>
              <div>Comanda dumneavoastra a fost inregistrata cu success ☑️</div>
              <div>O sa primiti un mail cu datele comenzii 📩 </div>
              <div>Veti fi redirectionat in 5 secunde</div>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
      <div className="cart-container">
        <div className="checkout-left">
          {!currentUser ? (
            <Form
              noValidate
              validated={validated}
              onSubmit={(event) => {
                handleSubmit(event);
              }}
            >
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label>Nume</Form.Label>
                  <Form.Control required type="text" />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Label>Prenume</Form.Label>
                  <Form.Control required type="text" />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom03">
                  <Form.Label>{"Numele companiei (optional)"}</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
                <Form.Text id="passwordHelpBlock" muted>
                  Numele companiei nu este un camp obligatoriu
                </Form.Text>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom04">
                  <Form.Label>Adresa</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">
                    Va rugam introduceti adresa
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom05">
                  <Form.Label>Oras</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">
                    Va rugam introduceti orasul
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom06">
                  <Form.Label>Judet</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">
                    Va rugam introduceti judetul
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom07">
                  <Form.Label>Cod postal</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">
                    Va rugam introduceti codul zip
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom08">
                  <Form.Label>Numar de telefon</Form.Label>
                  <Form.Control type="number" required />
                  <Form.Control.Feedback type="invalid">
                    Va rugam introduceti numarul de telefon
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom09">
                  <Form.Label>Adresa de email</Form.Label>
                  <Form.Control type="email" required />
                  <Form.Control.Feedback type="invalid">
                    Va rugam introduceti adresa de email valida
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Form.Group className="mb-3">
                <Form.Check label="Vreau sa primesc notificari pe email" />
              </Form.Group>
              <Button
                disabled={buttonDisabled}
                style={{ width: "100%", marginBottom: "20px" }}
                type="submit"
              >
                Trimite comanda
              </Button>
            </Form>
          ) : (
            <div>
              <h3 className="checkout-title">Selecteaza adresa de livrare:</h3>
              <ListGroup>
                {addresses.map((address, index) => {
                  return (
                    <ListGroup.Item
                      action
                      active={index === selectedAddress}
                      style={{ display: "flex", padding: "17px" }}
                      key={index}
                      onClick={() => {
                        setSelectedAddress(index);
                      }}
                    >
                      <Form.Check
                        style={{ marginRight: "10px" }}
                        type="checkbox"
                        checked={selectedAddress === index}
                      />
                      <div>
                        <div>{address.name + " - " + address.phoneNumber}</div>
                        <div>
                          {address.address +
                            " " +
                            address.city +
                            " " +
                            address.state}
                        </div>
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>

              <Button
                disabled={buttonDisabled}
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setShowA(true);
                  setButtonDisabled(true);
                  setTimeout(() => {
                    localStorage.removeItem("items");
                    updateCart([]);
                    history.push("/");
                  }, 5000);
                }}
              >
                Trimite comanda
              </Button>
            </div>
          )}
        </div>
        <div className="checkout-right">
          <div className="checkout-total-order">
            <h3 className="checkout-title">Coamanda dumneavoastra</h3>
            <table className="checkout-table">
              <thead>
                <tr>
                  <th className="checkout-table-item">Produs</th>
                  <th className="checkout-table-item">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {productsToCart?.map((product, index) => {
                  return (
                    <tr key={index}>
                      <th className="checkout-table-item">
                        <div>{product.productName}</div>
                        <div className="checkout-table-subitem">
                          Culoare: {product.color}
                        </div>
                        <div className="checkout-table-subitem">
                          Marime: {product.size}
                        </div>
                        <div className="checkout-table-subitem">
                          Cantitate: x{product.quantity}
                        </div>
                      </th>
                      <th className="checkout-table-item">
                        {product.price * product.quantity} lei
                      </th>
                    </tr>
                  );
                })}

                {/* <tr>
                <th className="checkout-table-item">
                  <div>Numele produsului</div>
                  <div className="checkout-table-subitem">Culoare: Roz</div>
                  <div className="checkout-table-subitem">Marime: S</div>
                  <div className="checkout-table-subitem">Cantitate: x2</div>
                </th>
                <th className="checkout-table-item">10 lei</th>
              </tr> */}
              </tbody>
              <tfoot>
                <tr>
                  <th className="checkout-table-item">Transport</th>
                  <td className="checkout-table-item">
                    <div>{SHIP_TAX} lei</div>
                  </td>
                </tr>
                {voucherValue > 0 ? (
                  <tr>
                    <th className="checkout-table-item">Voucher</th>
                    <td className="checkout-table-item">
                      <div>{voucherValue} lei</div>
                    </td>
                  </tr>
                ) : null}
                {/* <tr>
                <th className="checkout-table-item">Subtotal</th>
                <td className="checkout-table-item">
                  <div>0</div>
                </td>
              </tr> */}
                <tr>
                  <th className="checkout-table-item">Total</th>
                  <td className="checkout-table-item">
                    <div>
                      {voucherValue > 0
                        ? totalPrice + SHIP_TAX - voucherValue
                        : totalPrice + SHIP_TAX}{" "}
                      lei
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
