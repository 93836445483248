import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom";
import "./Men.css";
import {
  getAllProducts,
  getProductsByGender,
} from "../../service/ProductService";
import { ProductDetails } from "../product-details/ProductDetails";
import { Card } from "../../components/card/Card";
import { useShopData } from "../../redux/context";

export const Men = () => {
  let { path, url } = useRouteMatch();
  const history = useHistory();

  const [products, setProducts] = useState([]);

  const getProductUrl = (url, colors) => {
    return url + "-" + Object.keys(colors[0])[0];
  };

  useEffect(() => {
    getProductsByGender("barbati").then((response) => {
      setProducts(response);
    });
  }, []);

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <h2 className="men-head-text">Toate modele</h2>
          <div className="men-container-cards">
            {products?.map((product) => {
              return (
                <Card
                  key={product.id}
                  gender={product.gender}
                  image={getProductUrl(product.url, product.colors)}
                  name={product.name}
                  price={product.price}
                  url={product.url}
                />
              );
            })}
          </div>
        </Route>
        <Route path={`${path}/:topicId`}>
          <ProductDetails />
        </Route>
      </Switch>
    </div>
  );
};
