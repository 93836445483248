import React, { useEffect, useState } from "react";
import { Menu, ShoppingCart, User } from "react-feather";
import "./Header.css";
import logo from "../../svg/logo_black.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Badge, Button, ButtonGroup, Offcanvas } from "react-bootstrap";
import { useShopData } from "../../redux/context";
import { useAuth } from "../../service/AuthContext";
import { createNewShoppingCartService, getUserShoppingCart, getUserShoppingCartService } from "../../service/OrderService";

export const Header = () => {
  const history = useHistory();

  const { currentUser } = useAuth();
  const { updateCart } = useShopData();
  useEffect(() => {
    if (currentUser) {
      getUserShoppingCartService(currentUser.uid).then((response) => {
        updateCart(response.products);
      });
    } else {
    }
  }, []);

  const onNavigate = () => {
    history.push("/");
  };

  const [show, setShow] = useState(false);
  const { data } = useShopData();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="header">
        <img
          alt="logo"
          src={logo}
          style={{ width: "120px", cursor: "pointer" }}
          onClick={onNavigate}
        ></img>
        <div className="menu-items">
          <Badge
            style={{ fontSize: "13px" }}
            className="menu-item"
            bg="warning"
            text="dark"
            onClick={() => {
              history.push("/new");
            }}
          >
            Ultimele noutati
          </Badge>
          {/* <div className="menu-item"></div> */}
          <div
            className="menu-item"
            onClick={() => {
              history.push("/men");
            }}
          >
            Barbati
          </div>
          <div
            className="menu-item"
            onClick={() => {
              history.push("/women");
            }}
          >
            Femei
          </div>
          <div
            className="menu-item"
            onClick={() => {
              history.push("/kids");
            }}
          >
            Copii
          </div>
          <div
            className="menu-item"
            onClick={() => {
              history.push("/contact");
            }}
          >
            Contact
          </div>
        </div>
        <div className="shop-container">
          <div className="header-cart-container">
            <ShoppingCart
              className="header-icons"
              onClick={() => {
                history.push("/cart");
              }}
            ></ShoppingCart>
            {data?.cartItems.length != 0 ? (
              <Badge className="heaeder-badge" bg="secondary">
                {data?.cartItems?.length}
              </Badge>
            ) : null}
          </div>
          <User
            className="header-icons"
            onClick={() => {
              history.push("/account");
            }}
          />
          <div className="menu-items-show-hide">
            <div>|</div>
            <Menu className="menu-icon" onClick={handleShow} />
          </div>
        </div>
        <Offcanvas show={show} onHide={handleClose} className="my-class">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Meniu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="container-dropdown-menu">
              <Button
                variant="light"
                className="button-dropdown-menu"
                onClick={() => {
                  history.push("/new");
                  handleClose();
                }}
              >
                <Badge
                  style={{ fontSize: "18px" }}
                  // className="menu-item"
                  bg="warning"
                  text="dark"
                >
                  Ultimele noutati
                </Badge>
              </Button>
              <Button
                variant="light"
                className="button-dropdown-menu"
                onClick={() => {
                  history.push("/men");
                  handleClose();
                }}
              >
                Barbati 🧔🏻‍♂️
              </Button>
              <Button
                variant="light"
                className="button-dropdown-menu"
                onClick={() => {
                  history.push("/women");
                  handleClose();
                }}
              >
                Femei 👩🏻‍🦰
              </Button>
              <Button
                variant="light"
                className="button-dropdown-menu"
                onClick={() => {
                  history.push("/kids");
                  handleClose();
                }}
              >
                Copii 🍼
              </Button>
              <Button
                variant="light"
                className="button-dropdown-menu"
                onClick={() => {
                  history.push("/contact");
                  handleClose();
                }}
              >
                Contact 📱
              </Button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};
