import React, { useEffect, useState } from "react";
import "./AccountDetails.css";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import { Divider } from "../divider/Divider";
import { useAuth } from "../../service/AuthContext";
import { getUserById, updateUserDetails } from "../../service/UserService";
import { User } from "../../models/User";

export const AccountDetails = () => {
  const [validated, setValidated] = useState(false);
  const { currentUser, updateUserPassword, logout } = useAuth();
  const [userData, setUserData] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        console.log("Fetching User data..."); //TODO: Check why this block is called twice
        const userData = await getUserById(currentUser.uid);
        setUserData(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }

    setValidated(true);

    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());
    const user = new User(
      currentUser.uid,
      formDataObj["firstName"],
      formDataObj["lastName"],
      currentUser.email,
      null,
      null,
      null,
      null,
      null
    );
    updateUserDetails(user);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordsMatch(event.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordsMatch(event.target.value === password);
  };

  const onUpdatePassword = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());
    if(passwordsMatch) {
      updateUserPassword(formDataObj["newPassword"]);
    }
  };

  return (
    <div className="account-details-container">
      <h4>Detalii despre cont</h4>
      <div>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group md="4" controlId="validationCustom01">
              <Form.Label>First name</Form.Label>
              <Form.Control
                required
                type="text"
                name="firstName"
                placeholder="First name"
                defaultValue={userData.firstName}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group md="4" controlId="validationCustom02">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                required
                type="text"
                name="lastName"
                placeholder="Last name"
                defaultValue={userData.lastName}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Button style={{ marginTop: "1rem" }} type="submit">
                Salveaza modificarile
              </Button>
            </Form.Group>
          </Row>
        </Form>

        <Form noValidate validated={validated} onSubmit={onUpdatePassword}>
          <Divider />
          <h4 style={{ marginTop: "1rem" }}>Schimbarea parolei</h4>
          <Form.Group md="4" controlId="validationCustom01">
            <Form.Label>Noua Parola</Form.Label>
            <Form.Control
              required
              type="password"
              name="newPassword"
              value={password}
              onChange={handlePasswordChange}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group md="4" controlId="validationCustom01">
            <Form.Label>Confirma Noua Parola</Form.Label>
            <Form.Control
              required
              type="password"
              name="newPasswordCheck"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              isInvalid={!passwordsMatch}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>

          <Button style={{ marginTop: "1rem" }} type="submit">
            Schimba Parola
          </Button>
        </Form>
      </div>
    </div>
  );
};
