import React from "react";
import "./Footer.css";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-text">Copyright © 2023 CCV CODE</div>
    </div>
  );
};
