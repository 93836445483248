import React, { useEffect, useState } from "react";
import "./Addresses.css";
import { useShopData } from "../../redux/context";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Info, MapPin } from "react-feather";
import { ReactComponent as LocationSvg } from "../../img/location.svg";
import { createAddressService, getUserAddressesService } from "../../service/UserService";
import { useAuth } from "../../service/AuthContext";

export const Addresses = () => {
  const { currentUser, updateUserPassword, logout } = useAuth();
  const [activeForm, setActiveForm] = useState(false);
  const [addresses, setAddresses] = useState([]);

  const { updateAddresses } = useShopData();

  useEffect(() => {
    getUserAddressesService(currentUser.uid).then((response) => {
      setAddresses(response);
    });
  }, []);


  const MyVerticallyCenteredModal = (props) => {
    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
      } else {
        // event.preventDefault();
        // event.stopPropagation();
        const address = {
          name: form["addressNameId"].value,
          address: form["addressAddressId"].value,
          city: form["addressCityId"].value,
          state: form["addressStateId"].value,
          zipCode: form["addressZipId"].value,
          phoneNumber: form["addressPhoneId"].value,
          userId: currentUser.uid,
          email: currentUser.email
        };


        // let newAddresses = [...addresses];
        // newAddresses.push(address);
        // localStorage.setItem("addresses", JSON.stringify(newAddresses));
        // updateAddresses(newAddresses);
        setActiveForm(false);
        createAddressService(address)
        getUserAddressesService(currentUser.uid).then((response) => {
          setAddresses(response);
        });
      }
    };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Adauga adresa noua
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addresses-form-container">
            <Form
              noValidate
              validated={validated}
              onSubmit={(event) => {
                handleSubmit(event);
              }}
            >
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="addressNameId">
                  <Form.Label>Nume</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">
                    Va rugam introduceti numele
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="addressAddressId">
                  <Form.Label>Adresa</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">
                    Va rugam introduceti adresa
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="addressCityId">
                  <Form.Label>Oras</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">
                    Va rugam introduceti orasul
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="addressStateId">
                  <Form.Label>Judet</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">
                    Va rugam introduceti judetul
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="addressZipId">
                  <Form.Label>Cod postal</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">
                    Va rugam introduceti codul zip
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="addressPhoneId">
                  <Form.Label>Numar de telefon</Form.Label>
                  <Form.Control type="number" required />
                  <Form.Control.Feedback type="invalid">
                    Va rugam introduceti numarul de telefon
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button style={{ width: "40%" }} type="submit">
                  Salveaza
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <div className="addresses-container">
      <h4>Adrese</h4>
      {addresses.length < 1 ? (
        <Alert variant="info">
          <Info />
          {" Nu aveti nici o adresa salvata!"}
        </Alert>
      ) : null}
      <Button
        onClick={() => {
          setActiveForm(true);
        }}
      >
        Adauga adresa noua
      </Button>
      <div>
        <div className="addresses-cards-container">
          {addresses?.map((address, index) => {
            return (
              <div key={index} className="addresses-card">
                <div style={{ display: "flex" }}>
                  <LocationSvg className="addresses-card-svg" />
                  <div className="addresses-card-content">
                    <div className="addresses-card-content-text">
                      <div className="addresses-card-content-text-header">
                        {address.name} - {address.phoneNumber}
                      </div>
                      <div className="addresses-card-content-text-addr">
                        {address.address} {address.city} {address.state}
                      </div>
                    </div>
                    <div className="addresses-card-content-actions">
                      <div className="addresses-card-content-action">
                        Editeaza
                      </div>
                      <div className="addresses-card-content-action">
                        Sterge
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <MyVerticallyCenteredModal
        show={activeForm}
        onHide={() => setActiveForm(false)}
      />
    </div>
  );
};
