import React, { useState } from "react";
import "./ColorPicker.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
export const ColorPicker = (props) => {
  // const [active, setActive] = useState(false);

  const renderTooltip = (props, colorText, onSelectColor) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {colorText}
      </Tooltip>
    );
  };
  return (
    <div>
      <OverlayTrigger
        placement="top"
        // delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(props, props.colorText)}
      >
        <div
          className={
            props.index === props.active
              ? "product-details-color-picker-active"
              : "product-details-color-picker"
          }
          onClick={() => {
            props.onSelectColor(props.colorKey);
            // setActive(true);
          }}
        >
          <span
            style={{ backgroundColor: props.color }}
            className="product-details-color-inside"
          ></span>
        </div>
      </OverlayTrigger>
    </div>
  );
};
