import { createUserWithEmailAndPassword } from "firebase/auth";
import React from "react";
import { auth } from "../../firebase";
import { Button, Form } from "react-bootstrap";
import { createUser } from "../../service/UserService";

export const Register = () => {
  const register = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());
    console.log(formDataObj);
    createUserWithEmailAndPassword(
      auth,
      formDataObj["email"],
      formDataObj["password"]
    )
      .then((userCredential) => {
        const user = userCredential.user;
        if (user) {
          createUser(user.uid, user.email);
        }
      })
      .catch((error) => {
        console.log(error); //show proper error based on this
      });
  };

  return (
    <div>
      <h2 className="login-text">Creare cont</h2>
      <div className="login-container">
        <Form onSubmit={register}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Adresa de email</Form.Label>
            <Form.Control type="email" placeholder="Enter email" name="email" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Parola</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
            />
          </Form.Group>

          <Button style={{ width: "100%" }} variant="primary" type="submit">
            Creaza cont
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Register;
