import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./Card.css";

export const Card = (props) => {
  const history = useHistory();
  return (
    <div className="card-container">
      <div
        className="card-link"
        onClick={() => {
          switch (props.gender) {
            case "barbati":
              history.push("/men/" + props.url);
              break;
            case "femei":
              history.push("/women/" + props.url);
              break;
            case "copii":
              history.push("/kids/" + props.url);
              break;
            default:
              break;
          }
        }}
      >
        <img
          src={(() => {
            try {
              return require(`../../img/${props.image}.jpg`);
            } catch (error) {
              return require("../../img/720x960.png");
            }
          })()}
          alt="Avatar"
          className="card-image"
          style={{ width: "100%" }}
        />
        <div className="card-down-container">
          <h3 className="card-down-link-text">{props.name}</h3>
          <div className="card-down-link-text">{props.price} lei</div>
        </div>
        <div className="card-middle">
          <Button variant="primary">Detalii</Button>
        </div>
      </div>
    </div>
  );
};
