import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./pages/home/Home";
import { Contact } from "./pages/contact/Contact";
import { PageNotFound } from "./pages/404/NotFoud";
import { Header } from "./components/header/Header";
import { Login } from "./pages/login/Login";
import { Register } from "./pages/register/Register";
import "bootstrap/dist/css/bootstrap.min.css";
import { Account } from "./pages/account/Account";
import { Men } from "./pages/men/Men";
import { Cart } from "./pages/cart/Cart";
import PrivateRoute from "./service/PrivateRoute";
import { AuthProvider } from "./service/AuthContext";
import { InitialDataProvider } from "./redux/context";
import { Checkout } from "./pages/checkout/Checkout";
import { Women } from "./pages/women/Women";
import { Kids } from "./pages/kids/Kids";

export function App() {
  return (
    <InitialDataProvider>
      <Router>
        <AuthProvider>
          <Header></Header>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <PrivateRoute exact path="/account" component={Account} />
            <Route path="/men">
              <Men />
            </Route>
            <Route path="/women">
              <Women />
            </Route>
            <Route path="/kids">
              <Kids />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/cart">
              <Cart />
            </Route>
            <Route path="/checkout">
              <Checkout />
            </Route>

            <Route component={PageNotFound} />
          </Switch>
        </AuthProvider>
      </Router>
    </InitialDataProvider>
  );
}
