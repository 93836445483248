import db from "../firebase";
import { addDoc, query, setDoc, where } from "firebase/firestore";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";

export async function getOrderById(id) {
  const docRef = doc(db, "order", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log("No such document!");
    return null;
  }
}

export async function getOrdersByUserId(userId) {
  const userOrders = [];
  const userRef = collection(db, "orders");
  const q = query(
    userRef,
    where("userId", "==", userId),
    where("status", "!=", "unsubmitted")
  );
  const userDocs = await getDocs(q);

  userDocs.forEach((doc) => {
    const order = doc.data();
    order.id = doc.uid;
    userOrders.push(order);
  });

  return userOrders;
}

async function getUserShoppingCart(userId) {
  var shoppingCart = null;
  const userRef = collection(db, "orders");
  const q = query(
    userRef,
    where("userId", "==", userId),
    where("status", "==", "unsubmitted")
  );
  const userDocs = await getDocs(q);

  userDocs.forEach((doc) => {
    const order = doc.data();
    order.id = doc.id;
    shoppingCart = order;
  });

  return shoppingCart;
}

export async function getUserShoppingCartService(userId) {
    const shoppingCart = await getUserShoppingCart(userId);
    if (shoppingCart) {
      return shoppingCart;
    } else {
      await createNewShoppingCartService(userId);
      const shoppingCart = await getUserShoppingCart(userId);
      return shoppingCart;
    }
}

async function createNewShoppingCartService(userId) {
  const newCart = {
    status: "unsubmitted",
    userId: userId,
    products: [],
  };
 
  await addDoc(collection(db, "orders"), newCart);
}

// export async function addProductToCart(userId, product) {
//   // const testProduct = {
//   //   productId: "id",
//   //   imageUrl: "imgUrl",
//   //   productName: "Foloseste-l",
//   //   price: "45",
//   //   quantity: 1,
//   //   color: "green",
//   //   size: "L",
//   //   url: "pls-use-it",
//   // };

//   // If user is logged in we should have userId, otherwise we will create a temporary userId and store as cookie with expiration time
//   // We save the cart linked to that userId
//   if (userId) {
//     const userRef = collection(db, "orders");
//     const q = query(
//       userRef,
//       where("userId", "==", userId),
//       where("status", "==", "unsubmitted")
//     );
//     const querySnapshot = await getDocs(q);

//     // If a cart is already in DB, use that, otherwise create a new one
//     if (querySnapshot.docs[0].exists()) {
//       const updatedCart = querySnapshot.docs[0].data();
//       updatedCart.products.push(product);
//       await setDoc(doc(db, "orders", querySnapshot.docs[0].id), updatedCart);
//     } else {
//       const newCart = {
//         status: "unsubmitted",
//         userId: userId,
//         products: [product],
//       };

//       await setDoc(doc(db, "orders"), newCart);
//     }
//   } else {
//     var tempUserId = generateRandomId();
//     var exdate = new Date();
//     exdate.setDate(exdate.getDate() + 5); // 5 days
//     document.cookie = `userId=${tempUserId}; expires=${exdate.toUTCString()}`;
//   }
// }

export async function submitOrderService(userId, selectedAddress) {
  const order = await getUserShoppingCart(userId);
  order.address = selectedAddress;
  order.status = "submitted";
  order.orderDate = new Date();
  await setDoc(doc(db, "orders", order.id), order);
}

export async function updateCartProductsService(userId, products) {
  const order = await getUserShoppingCart(userId);
  order.products = products;
  await setDoc(doc(db, "orders", order.id), order);
}

export function generateRandomId() {
  return "temp_usr_" + Math.random().toString(36).substr(2, 9);
}
