import React, { useEffect, useRef, useState } from "react";
import "./Cart.css";
import { Button, Form, Table } from "react-bootstrap";
import { ArrowDown, ArrowUp } from "react-feather";
import { useShopData } from "../../redux/context";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  addProductToCart,
  getOrdersByUserId,
  getUserShoppingCart,
  getUserShoppingCartService,
  updateCartProductsService,
} from "../../service/OrderService";
import { useAuth } from "../../service/AuthContext";

// import { Table } from "react-feather";

export const Cart = () => {
  const [data, setData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [voucherAmount, setVoucherAmount] = useState(0);
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherEnabled, setVoucherEnabled] = useState(true);
  const productsToCart = useShopData().data.cartItems;
  const { updateCart, updateVoucher } = useShopData();
  const history = useHistory();
  const { currentUser } = useAuth();

  function calculateTotalPrice(data) {
    return data?.map((item) => ({
      ...item,
      totalPrice: item.price * item.quantity,
    }));
  }

  const calculateSum = (productsToCart) => {
    let sum = 0;
    productsToCart?.forEach(({ totalPrice }) => {
      sum += totalPrice;
    });
    return sum;
  };

  // useEffect(() => {
  //   async function fetchData() {
  //     const orders = await getOrdersByUserId(currentUser.uid);
  //     console.log(orders);
  //   }
  //   addProductToCart(currentUser.uid, null);
  //   fetchData();
  // }, []);

  useEffect(() => {
    setData(calculateTotalPrice(productsToCart));
    calculateSum(productsToCart);
  }, [productsToCart]);

  useEffect(() => {
    setTotalPrice(calculateSum(data));
  }, [data]);

  useEffect(() => {
    if (currentUser) {
      getUserShoppingCartService(currentUser.uid).then((response) => {
        updateCart(response.products);
      });
    }
  }, []);

  const handleQuantityChange = (index, newQuantity) => {
    const newData = [...data];
    newData[index].quantity = newQuantity;
    newData[index].totalPrice = newData[index].price * newQuantity;
    updateCartProductsService(currentUser.uid, newData);
    // localStorage.setItem("items", JSON.stringify(newData));
    updateCart(newData);
  };

  const handleDeleteItem = (index) => {
    const newItems = [...data];
    // Remove the item at the specified index
    newItems.splice(index, 1);
    updateCartProductsService(currentUser.uid, newItems);
    updateCart(newItems);
    // Update the state with the new array
    // setData(newItems);
  };

  const SHIP_TAX = 20;
  return (
    <div>
      <div className="cart-container">
        <div className="cart-products">
          {data?.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th>Produsul</th>
                  <th>Pretul</th>
                  <th>Cantiatea</th>
                  <th>Suma totala</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((product, index) => {
                  return (
                    <tr key={index}>
                      <td data-title="Nr.">
                        <div className="cart-product-item-img">
                          <img
                            alt="img"
                            className="cart-hidden-mobile"
                            // src={}
                            src={(() => {
                              try {
                                return require(`../../img/${product.imageUrl}.jpg`);
                              } catch (error) {
                                return require("../../img/720x960.png");
                              }
                            })()}
                          ></img>
                          <div className="container-product-name-size">
                            <a
                              className="cart-product-link"
                              href={"/men/" + product.url}
                            >
                              {product.productName}
                            </a>
                            <div>
                              {product.color} , {product.size}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td data-title="Data">
                        <div className="cart-product-item">
                          {product.price} lei
                        </div>
                      </td>
                      <td data-title="" className="cart-product-item">
                        <Arrow
                          quantity={product.quantity}
                          onChangeQuantity={(newQuantity) => {
                            handleQuantityChange(index, newQuantity);
                          }}
                        />
                      </td>
                      <td data-title="Suma totala">
                        <div style={{ paddingTop: "16px" }}>
                          <div style={{ fontWeight: "bold" }}>
                            {product.quantity * product.price} lei
                          </div>
                          <button
                            className="cart-product-delete-button"
                            onClick={() => handleDeleteItem(index)}
                          >
                            Sterge
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <h3 style={{ textAlign: "center", marginTop: "20px" }}>
              Cosul dumneavoastra este gol.
            </h3>
          )}
        </div>
        <div className="cart-pay">
          <div>
            <div className="cart-apply-coupon">
              <h2 className="cart-apply-coupon-text">Aplica un cupon</h2>
              <Form.Control
                type="text"
                value={voucherCode}
                placeholder="Codul cuponului"
                onChange={(event) => {
                  setVoucherCode(event.target.value);
                }}
                disabled={!voucherEnabled}
              />
              <Button
                className="cart-apply-coupon-button"
                variant="secondary"
                onClick={() => {
                  if (voucherCode.toUpperCase() === "VLAD10") {
                    setVoucherAmount(50);
                    setVoucherEnabled(false);
                    updateVoucher({
                      voucher: voucherCode.toUpperCase(),
                      voucherValue: 50,
                    });
                  }
                }}
              >
                Aplica cuponul
              </Button>
            </div>
            <div className="cart-total">
              <h2 className="cart-apply-coupon-text">Totalul cosului</h2>
              <Table className="cart-table">
                <tbody>
                  <tr>
                    <td className="cart-table-description">Subtotal</td>
                    <td>{totalPrice} lei</td>
                  </tr>
                  {data?.length > 0 ? (
                    <tr>
                      <td className="cart-table-description">Transport</td>
                      <td>{SHIP_TAX} lei</td>
                    </tr>
                  ) : null}
                  {voucherAmount !== 0 && data.length > 0 ? (
                    <tr>
                      <td className="cart-table-description">Voucher</td>
                      <td>- {voucherAmount} lei</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  <tr>
                    <td className="cart-table-description">Total</td>
                    <td style={{ fontWeight: "bold" }}>
                      {voucherAmount !== 0
                        ? data.length > 0
                          ? totalPrice + SHIP_TAX - voucherAmount
                          : totalPrice
                        : data.length > 0
                        ? totalPrice + SHIP_TAX
                        : totalPrice}
                      lei
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Button
                onClick={() => {
                  history.push("/checkout");
                }}
                disabled={data.length < 1}
                className="cart-total-button"
                variant="success"
              >
                Plaseaza comanda
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Arrow = (props) => {
  // const [value, setValue] = useState(props.quantity);
  return (
    <div className="arrow-container">
      <div className="arrow-arrows">
        <button
          className="arrow-up"
          type="button"
          onClick={() => {
            // setValue(value + 1);
            props.onChangeQuantity(props.quantity + 1);
          }}
        >
          <ArrowUp className="arrow-up-icon" />
        </button>
        <button
          className="arrow-down"
          type="button"
          onClick={() => {
            // if (value <= 1) {
            //   setValue(1);
            // } else {
            //   setValue(value - 1);
            // }
            if (props.quantity > 1) {
              props.onChangeQuantity(props.quantity - 1);
            }
          }}
        >
          <ArrowDown className="arrow-up-icon" />
        </button>
      </div>
      <input
        className="arrow-input"
        value={props.quantity}
        type="number"
        disabled
      ></input>
    </div>
  );
};
