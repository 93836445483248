import React, { useRef, useState } from "react";
import "./Orders.css";
import { Button, Table } from "react-bootstrap";
import { Eye } from "react-feather";

export const Orders = () => {
  const data = [
    {
      orderId: "1",
      date: "September 26, 2023",
      status: "Pending",
      total: "500",
      statusText: "Procesata",
    },
    {
      orderId: "2",
      date: "October 10, 2023",
      status: "Completed",
      total: "750",
      statusText: "Completa",
    },
    {
      orderId: "3",
      date: "November 15, 2023",
      status: "Cancelled",
      total: "320",
      statusText: "Anulata",
    },
    {
      orderId: "4",
      date: "December 5, 2023",
      status: "Completed",
      total: "920",
      statusText: "Completa",
    },
    {
      orderId: "5",
      date: "January 20, 2024",
      status: "Shipped",
      total: "620",
      statusText: "In livrare",
    },
    {
      orderId: "6",
      date: "February 8, 2024",
      status: "Pending",
      total: "430",
      statusText: "Procesata",
    },
    {
      orderId: "7",
      date: "March 5, 2024",
      status: "Completed",
      total: "1100",
      statusText: "Completa",
    },
    {
      orderId: "8",
      date: "April 15, 2024",
      status: "Pending",
      total: "870",
      statusText: "Procesata",
    },
    {
      orderId: "9",
      date: "May 12, 2024",
      status: "Cancelled",
      total: "250",
      statusText: "Anulata",
    },
  ];
  return (
    <div className="orders-container">
      <h4>Detalii despre comenzi</h4>

      <Table className="table-borderless table-condensed " striped>
        <thead className="order-table-head">
          <tr>
            <th>Nr.</th>
            <th>Data</th>
            <th>Status</th>
            <th>Suma totala</th>
            <th>Actiuni</th>
          </tr>
        </thead>
        <tbody className="order-table-content">
          {data.map((order) => {
            return (
              <tr key={order.orderId}>
                <td data-title="Nr." className="order-td">
                  #{order.orderId}
                </td>
                <td data-title="Data" className="order-td">
                  {order.date}
                </td>
                <td data-title="" className="order-td">
                  <Processing
                    text={order.status}
                    statusText={order.statusText}
                  />
                </td>
                <td data-title="Suma totala" className="order-td">
                  {order.total} lei
                </td>
                <td data-title="Actiuni" className="order-td">
                  <Button className="order-action-button" variant="primary">
                    <Eye className="order-action" />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

const MobileVersionOrders = () => {
  return <div></div>;
};

const Processing = (props) => {
  const color = useRef("grey");

  switch (props.text) {
    case "Pending":
      color.current = "#fcaa2f";
      break;
    case "Completed":
      color.current = "#2ecc71";
      break;
    case "Cancelled":
      color.current = "#e74c3c";
      break;
    case "Shipped":
      color.current = "#3498db";
      break;
    default:
      break;
  }

  return (
    <div style={{ background: color.current }} className="order-processing">
      {props.statusText}
    </div>
  );
};
