export class User {
    constructor(uuid, firstName, lastName, email, phoneNumber, adress, city, county, postalCode) {
      this.uuid = uuid;
      this.firstName = firstName;
      this.lastName = lastName;
      this.email = email;
      this.phoneNumber = phoneNumber;
      this.adress = adress;
      this.city = city;
      this.county = county;
      this.postalCode = postalCode;
    }
  }
  
  // Firestore data converter
  export const UserConverter = {
    toFirestore: (user) => {
      return {
        uuid: user.uuid,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        adress: user.adress,
        city: user.city,
        county: user.county,
        postalCode: user.postalCode
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new User(
        data.uuid,
        data.firstName,
        data.lastName,
        data.email,
        data.phoneNumber,
        data.adress,
        data.city,
        data.county,
        data.postalCode
      );
    },
  };
  