import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { getProductsByUrl } from "../../service/ProductService";
import {
  Breadcrumb,
  Button,
  Carousel,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./ProductDetails.css";
import { Divider } from "../../components/divider/Divider";
import { ColorPicker } from "../../components/color-picker/ColorPicker";
import { SizePicker } from "../../components/size-picker/SizePicker";
import { Footer } from "../../components/footer/Footer";
import { useShopData } from "../../redux/context";
import { getUserShoppingCartService, updateCartProductsService } from "../../service/OrderService";
import { useAuth } from "../../service/AuthContext";

export const ProductDetails = () => {
  const [quantity, setQuantity] = useState(1);

  const { currentUser } = useAuth();
  const [currentColorSelection, setCurrentColorSelection] = useState("");
  const [activeIndexColorSelection, setActiveIndexColorSelection] = useState(0);

  const [currentSizeSelection, setCurrentSizeSelection] = useState("");

  const [currentProduct, setCurrentProduct] = useState("");

  const genderMapping = { barbati: "Men", femei: "Women", copii: "Kids" };

  useEffect(() => {
    getProductsByUrl(topicId).then((response) => {
      setCurrentColorSelection(Object.keys(response[0]?.colors?.[0])?.[0]);
      setCurrentProduct(response[0]);
      console.log(currentUser);
    })
  }, []);

  let { topicId } = useParams();

  const history = useHistory();
  const getProductUrl = (url, colors, index) => {
    return url + "-" + colors;
  };

  const renderImages = () => {
    return [1, 2, 3].map((index) => {
      return (
        <Carousel.Item key={index}>
          <img
            className="product-details-image"
            alt="First slide"
            src={(() => {
              try {
                return require(`../../img/${getProductUrl(
                  currentProduct?.url,
                  currentColorSelection,
                  index
                )}.jpg`);
              } catch (error) {
                return require("../../img/720x960.png");
              }
            })()}
          />
          <Carousel.Caption>
            <h3>
              {
                currentProduct?.colors?.[0]?.[currentColorSelection]
                  ?.colorText
              }
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
      );
    });
  };

  const renderColorPicker = () => {
    return Object?.keys(currentProduct?.colors?.[0] || {}).map(
      (color, index) => {
        return (
          <div key={index}>
            <ColorPicker
              index={index}
              active={activeIndexColorSelection}
              color={currentProduct?.colors[0][color].colorHex}
              colorText={currentProduct?.colors[0][color].colorText}
              colorKey={color}
              onSelectColor={(color) => {
                setCurrentColorSelection(color);
                setActiveIndexColorSelection(index);
                setCurrentSizeSelection("");
              }}
            />
          </div>
        );
      }
    );
  };

  const renderSizePicker = () => {
    return currentProduct?.colors?.[0]?.[
      currentColorSelection
    ]?.sizes?.map((element, index) => {
      const size = Object.keys(element)[0];
      const quantity = element?.[size];
      return (
        <div key={index}>
          <SizePicker
            disabled={quantity < 1}
            sizeText={size}
            currentSelection={currentSizeSelection}
            onSelectSize={() => {
              setCurrentSizeSelection(size);
            }}
          />
        </div>
      );
    });
  };

  const updateCartHandle = () => {
    const dataToSave = {
      productId: currentProduct?.id,
      imageUrl: getProductUrl(
        currentProduct?.url,
        currentColorSelection,
        1
      ),
      productName: currentProduct?.name,
      price: currentProduct?.price,
      quantity: quantity,
      color: currentColorSelection,
      size: currentSizeSelection,
      url: currentProduct?.url,
    };

    getUserShoppingCartService(currentUser.uid).then((response) => {
      response.products.push(dataToSave);
      updateCartProductsService(currentUser.uid, response.products);
    })
  };
  return (
    <div>
      <Breadcrumb className="product-details-link-history">
        <Breadcrumb.Item
          onClick={() => {
            history.push("/");
          }}
        >
          Acasa
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.push("/" + genderMapping[currentProduct?.gender]);
          }}
        >
          {genderMapping[currentProduct?.gender]}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{currentProduct?.name}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="product-details-container">
        <Carousel className="product-details-carousel">
          {renderImages()}
        </Carousel>
        <div className="product-details-2nd-container">
          <div className="product-details-name">
          {currentProduct?.name}
            {/* {testCurrentProduct.name} */} 
          </div>
          <div className="product-details-price">
            Pret: {currentProduct?.price} lei
          </div>
          <Divider />
          <div className="product-details-description">
            The Classique Dress Shirt is a refined essential. With meticulous
            craftsmanship and a classic design, it offers comfort and
            sophistication. Ideal for formal or professional occasions, it pairs
            seamlessly with suits or dress pants, making it a versatile choice
            for a polished look.
          </div>
          <Divider />
          <div className="product-details-generic-title">Culori</div>
          <div className="product-details-color-container">
            {renderColorPicker()}
          </div>
          <div className="product-details-generic-title">Marime</div>
          <div className="product-details-size-container">
            {renderSizePicker()}
          </div>
          <div className="product-details-buttons-container">
            <Button
              className="product-details-button-plus"
              variant="secondary"
              onClick={() => {
                setQuantity(quantity + 1);
              }}
            >
              +
            </Button>
            <Form.Control
              className="product-details-input"
              size="lg"
              placeholder=""
              value={quantity}
              type="number"
              disabled
              onChange={(event) => {
                try {
                  if (event.target.value === "") {
                    setQuantity(1);
                    return;
                  }
                  const quantity = parseInt(event.target.value);
                  setQuantity(quantity <= 1 ? 1 : quantity);
                } catch (error) {
                  setQuantity(1);
                }
              }}
            />
            <Button
              className="product-details-button-minus"
              variant="secondary"
              onClick={() => {
                if (quantity !== 1) setQuantity(quantity - 1);
              }}
            >
              -
            </Button>
          </div>
          <div className="product-details-add-cart">
            <Button
              disabled={!currentSizeSelection}
              className="product-details-buy-button"
              variant="primary"
              onClick={() => {
                updateCartHandle();
              }}
            >
              Adauga in cos
            </Button>
          </div>
        </div>
      </div>
      <div style={{ height: "50px" }}></div>
    </div>
  );
};
