import React, { useRef } from "react";
import "./index.css";
import men from "../../img/men-banner.jpg";
import women from "../../img/women-banner.jpg";
import kids from "../../img/kid-banner.jpg";
import newBanner from "../../img/new-banner.jpg";
import { Footer } from "../../components/footer/Footer";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Redirect } from "react-router-dom";

export const Home = () => {
  const auth = getAuth();
  const myRef = useRef(null);

  // onAuthStateChanged(auth, (user) => {
  //   if (!user) {
  //     console.log("User not logged in");
  //   }
  // });

  return (
    <div>
      <div>
        <div
          className="main-banner-home"
          onClick={() => {
            myRef.current.scrollIntoView();
          }}
        ></div>
      </div>
      {/* <div className="page-width">
        <div className="container-adv">
          <div className="mini-container-adv">
            <h2>Free Shipping</h2>
            <p>Get Free Shipping on all orders over $75.</p>
          </div>
          <div className="mini-container-adv">
            <h2>Best Price</h2>
            <p>We offer the best prices on all our products.</p>
          </div>
          <div className="mini-container-adv">
            <h2>Great Service</h2>
            <p>Our customer service is available 24/7.</p>
          </div>
        </div>
      </div> */}
      <h2
        ref={myRef}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        Categorii
      </h2>
      <div className="page-width">
        <div className="category-container">
          <div className="men-container">
            <img className="category-img" src={newBanner} alt="Shop men" />
            <div className="category-buttons" href="#">
            Barbati
          </div>
          </div>
          <div className="women-container">
            <img className="category-img" src={men} alt="Shop men" />
            {/* <div className="category-buttons" href="#">
            Femei
          </div> */}
          </div>
          <div className="women-container">
            <img className="category-img" src={women} alt="Shop men" />
            {/* <div className="category-buttons" href="#">
            Femei
          </div> */}
          </div>
          <div className="women-container">
            <img className="category-img" src={kids} alt="Shop men" />
            {/* <div className="category-buttons" href="#">
            Copii
          </div> */}
          </div>
        </div>
      </div>

      <div className="page-width featured-products">
        <h2>Featured Products</h2>
        <div>There is no product to display</div>
      </div>
      <Footer />
    </div>
  );
};
