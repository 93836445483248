import db from "../firebase";
import { collection } from "firebase/firestore";
import { doc, getDoc, getDocs, addDoc } from "firebase/firestore";
import { Product, productConverter } from "../models/Product";

export async function getAllProducts() {
  var allProducts = [];
  const querySnapshot = await getDocs(collection(db, "products"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    allProducts.push({ ...doc.data(), ...doc.id });
  });
  return allProducts;
}

export async function getProductsByGender(gender) {
  var productsByGender = [];
  const querySnapshot = await getDocs(collection(db, "products"));
  querySnapshot.forEach((doc) => {
    if (doc.data().gender === gender) {
      var data = doc.data();
      data.id = doc.id;
      productsByGender.push(data);
    }
  });
  return productsByGender;
}

export async function getProductById(id) {
  const docRef = doc(db, "products", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    return null;
  }
}

// Create mock product
export async function addProduct(product) {
  const collectionRef = collection(db, "products");
  const allColors = {
    red: {
      sizes: [{ S: 0 }, { M: 10 }, { L: 0 }, { XL: 0 }, { XXL: 0 }],
      colorText: "Visiniu",
      colorHex: "#a32131",
    },
    gray: {
      sizes: [{ S: 20 }, { M: 0 }, { L: 0 }, { XL: 0 }, { XXL: 0 }],
      colorText: "Gri",
      colorHex: "#46464c",
    },
    green: {
      sizes: [{ S: 20 }, { M: 0 }, { L: 0 }, { XL: 0 }, { XXL: 0 }],
      colorText: "Verde",
      colorHex: "#53573d",
    }
  }


  const grayGreenColors = {
    gray: {
      sizes: [{ S: 20 }, { M: 0 }, { L: 0 }, { XL: 0 }, { XXL: 0 }],
      colorText: "Gri",
      colorHex: "#46464c",
    },
    green: {
      sizes: [{ S: 20 }, { M: 0 }, { L: 0 }, { XL: 0 }, { XXL: 0 }],
      colorText: "Verde",
      colorHex: "#53573d",
    }
  }


  const womenBlackGreen = {
    black: {
      sizes: [{ S: 20 }, { M: 0 }, { L: 0 }, { XL: 0 }, { XXL: 0 }],
      colorText: "Negru",
      colorHex: "#1d1d1d",
    },
    green: {
      sizes: [{ S: 20 }, { M: 0 }, { L: 0 }, { XL: 0 }, { XXL: 0 }],
      colorText: "Verde",
      colorHex: "#35521a",
    }
  }

  const munti =  {
    colors: [
      allColors
    ],
    url: "munti",
    gender: "barbati",
    price: "45",
    name: "Tricou 'Mountains"
  }

  const urs =  {
    colors: [
      allColors
    ],
    url: "urs",
    gender: "barbati",
    price: "45",
    name: "Tricou 'Mountain bear'"
  }

  const tent =  {
    colors: [
      allColors
    ],
    url: "tent",
    gender: "barbati",
    price: "45",
    name: "Tricou 'Camping'"
  }

  const moonSun = {
    colors: [
      grayGreenColors
    ],
    url: "moon-sun",
    gender: "barbati",
    price: "45",
    name: "Tricou 'Both Worlds'"
  }

  const offRoad = {
    colors: [
      grayGreenColors
    ],
    url: "off-road",
    gender: "barbati",
    price: "45",
    name: "Tricou 'Off-Road'"
  }


  const travel = {
    colors: [
      grayGreenColors
    ],
    url: "travel",
    gender: "barbati",
    price: "45",
    name: "Tricou 'Travel'"
  }

  const freeHugs = {
    colors: [
      womenBlackGreen
    ],
    url: "free-hugs",
    gender: "femei",
    price: "45",
    name: "Tricou 'Free Hugs'"
  }

  const teaShirt = {
    colors: [
      womenBlackGreen
    ],
    url: "tea-shirt",
    gender: "femei",
    price: "45",
    name: "Tricou 'Tea Shirt'"
  }



  await addDoc(collectionRef, freeHugs);
  await addDoc(collectionRef, teaShirt);
}

export async function getProductsByUrl(url) {
  var product = [];
  const querySnapshot = await getDocs(collection(db, "products"));
  querySnapshot.forEach((doc) => {
    if (doc.data().url === url) {
      var item = doc.data();
      item.id = doc.id;
      product.push(item);
    }
  });
  return product;
}
