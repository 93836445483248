import React, { useEffect, useState } from "react";
import { Button, Form, ListGroup } from "react-bootstrap";
import "./Account.css";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { auth } from "../../firebase";
import { getUserById } from "../../service/UserService";
import { Home, MapPin, Power, ShoppingBag, User } from "react-feather";
import { AccountDetails } from "../../components/account-details/AccountDetails";
import { Orders } from "../../components/orders/Orders";
import { useAuth } from "../../service/AuthContext";
import { addProduct } from "../../service/ProductService";
import { Addresses } from "../../components/addresses/Addresses";
import Cookies from 'js-cookie';

export const Account = () => {
  const history = useHistory();
  const { hash } = useLocation();
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const [currentTab, setCurrentTab] = useState();

  const TABS = {
    DASHBOARD: "dashboard",
    ORDERS: "orders",
    ADDRESSES: "addresses",
  };

  useEffect(() => {
    if (!hash) {
      history.push("#dashboard");
      setCurrentTab(TABS.DASHBOARD);
    } else {
      switch (hash.replace("#", "")) {
        case TABS.DASHBOARD:
          setCurrentTab(TABS.DASHBOARD);
          break;
        case TABS.ORDERS:
          setCurrentTab(TABS.ORDERS);
          break;
        case TABS.ADDRESSES:
          setCurrentTab(TABS.ADDRESSES);
          break;
        default:
          history.push("#dashboard");
          setCurrentTab(TABS.DASHBOARD);
          break;
      }
    }
    switchContent();
  }, []);

  useEffect(() => {
    switchContent();
  }, [currentTab]);

  const switchContent = () => {
    switch (currentTab) {
      case TABS.DASHBOARD:
        return <AccountDetails />;
      case TABS.ORDERS:
        return <Orders />;
      case TABS.ORDERS:
        return <Orders />;
      case TABS.ADDRESSES:
        return <Addresses />;
      default:
        break;
    }
  };

  async function handleLogout() {
    // await addProduct();
    setError("");
    Cookies.remove('userId');
    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  async function testHandle() {
    await addProduct();
  }

  return (
    <div>
      <div className="account-container">
        <div className="account-left-panel">
          <h4>Contul meu</h4>
          <ListGroup>
            <ListGroup.Item
              className="account-left-panel-item"
              action
              onClick={() => {
                setCurrentTab(TABS.DASHBOARD);
                history.push("#" + TABS.DASHBOARD);
              }}
              active={TABS.DASHBOARD === currentTab}
            >
              <User className="account-svg" />
              Dashboard
            </ListGroup.Item>

            <ListGroup.Item
              action
              className="account-left-panel-item"
              onClick={() => {
                setCurrentTab(TABS.ORDERS);
                history.push("#" + TABS.ORDERS);
              }}
              active={TABS.ORDERS === currentTab}
            >
              <ShoppingBag className="account-svg" />
              Comenzi
            </ListGroup.Item>
            <ListGroup.Item
              action
              className="account-left-panel-item"
              active={TABS.ADDRESSES === currentTab}
              onClick={() => {
                setCurrentTab(TABS.ADDRESSES);
                history.push("#" + TABS.ADDRESSES);
              }}
            >
              <MapPin className="account-svg" />
              Adrese de livrare
            </ListGroup.Item>
            <ListGroup.Item
              action
              // href="#link4"
              className="account-left-panel-item"
              onClick={handleLogout}
            >
              <Power className="account-svg" />
              Delogare
            </ListGroup.Item>
          </ListGroup>
        </div>
        {switchContent()}
      </div>
    </div>
  );
};
