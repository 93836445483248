import db from "../firebase";
import { setDoc } from "firebase/firestore";
import { doc, getDoc, getDocs, collection, query, where, addDoc, deleteDoc } from "firebase/firestore";
import { UserConverter } from "../models/User";


export async function getUserById(id) {
    const docRef = doc(db, "users", id);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such document!");
      return null;
    }
}

export async function updateUserDetails(user) {
    await setDoc(doc(db, "users", user.uuid), UserConverter.toFirestore(user)) 

}


export async function createUser(uuid, mail) {
    await setDoc(doc(db, "users", uuid), {email: mail}) 
}

export async function getUserAddressesService(userId) {
  var addresses = [];
  const userRef = collection(db, "addresses");
  const q = query(
    userRef,
    where("userId", "==", userId),
  );
  const userDocs = await getDocs(q);

  userDocs.forEach((doc) => {
    const address = doc.data();
    address.id = doc.id;
    addresses.push(address);
  });

  return addresses;
}


export async function createAddressService(address) {
  await addDoc(collection(db, "addresses"), address);
}

export async function deleteAddressService(addressId) {
  await deleteDoc(doc(db, 'addresses', addressId));
}
