import { createContext, useContext, useEffect, useState } from "react";
import { getProductsByGender } from "../service/ProductService";
import {
  createNewShoppingCartService,
  getUserShoppingCart,
} from "../service/OrderService";

// Create the initial context
const InitialDataContext = createContext();

// Create a provider component
export const InitialDataProvider = ({ children }) => {
  let addresses = [];
  const localStorageAddresses = localStorage.getItem("addresses");
  if (localStorageAddresses !== null) {
    addresses = JSON.parse(localStorageAddresses);
  }

  const [data, setData] = useState({
    allProducts: [],
    user: {},
    cartItems: [],
    orders: [],
    selectedProduct: {},
    voucher: {
      voucher: "",
      voucherValue: 0,
    },
    addresses: addresses,
  });

  const updateSelectedProduct = (newData) => {
    setData({ ...data, selectedProduct: newData });
  };

  const updateCart = (cartItems) => {
    setData({ ...data, cartItems: cartItems });
  };

  const updateVoucher = (voucher) => {
    setData({ ...data, voucher: voucher });
  };
  const updateAddresses = (addresses) => {
    setData({ ...data, addresses: addresses });
  };

  const updateAllProducts = (products) => {
    setData({ ...data, allProducts: products });
  };

  return (
    <InitialDataContext.Provider
      value={{
        data,
        updateSelectedProduct,
        updateCart,
        updateVoucher,
        updateAddresses,
        updateAllProducts
      }}
    >
      {children}
    </InitialDataContext.Provider>
  );
};

// Custom hook to consume the initial data
export const useShopData = () => useContext(InitialDataContext);
