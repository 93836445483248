import React, { useState } from "react";
import "./SizePicker.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const SizePicker = (props) => {
  // const [active, setActive] = useState(false);

  const renderTooltip = (props, sizeText) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {sizeText}
      </Tooltip>
    );
  };
  //   return ;

  return (
    <div>
      <OverlayTrigger
        placement="top"
        // delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(props, props.sizeText)}
      >
        <div
          onClick={() => {
            // setActive(!active);
            props.onSelectSize();
          }}
          className={
            props.disabled
              ? "size-picker-container-disabled"
              : "size-picker-container"
          }
          style={{
            boxShadow:
              props.currentSelection === props.sizeText
                ? "0 0 0 2px black"
                : "",
          }}
        >
          <span className="size-picker-span">{props.sizeText}</span>
        </div>
      </OverlayTrigger>
    </div>
  );
};
