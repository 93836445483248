import React from "react";
import "./NotFound.css";

export const PageNotFound = () => {
  return (
    <div className="container-404">
      <div className="placeholder-404"></div>
      <div className="image-404"></div>
      <div className="rest-of-the-space-404"></div>
    </div>
  );
};
