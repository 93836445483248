import React from "react";
import {MessageCircle} from "react-feather";

export const Contact = () => {
  return (
  <>
  <MessageCircle></MessageCircle>
  
  </>
  );
};
