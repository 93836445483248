import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./Login.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuth } from "../../service/AuthContext";

export const Login = () => {

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const { login } = useAuth()
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();
      try {
        const formData = new FormData(e.target);
        const formDataObj = Object.fromEntries(formData.entries());
        setError("")
        setLoading(true)
        await login(formDataObj["email"], formDataObj["password"])
        history.push("/account") 
      } catch {
        setError("Failed to log in")
      }
  
      setLoading(false)
  };

  return (
    <div>
      <h2 className="login-text">Logare</h2>
      <div className="login-container">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Adresa de email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Parola</Form.Label>
            <Form.Control type="password" placeholder="Password" name="password" />
          </Form.Group>

          <Button style={{ width: "100%" }} variant="primary" type="submit">
            Intra in cont
          </Button>
          <Link to="/register">
          <Button style={{ width: "100%", marginTop: "10px" }} variant="primary">
            Nu ai cont? Inregistreaza-te
          </Button>
        </Link>
        </Form>
      </div>
    </div>
  );
};
